<template>
<div>
    <div class="tyc-header " >
        <div class="tyc-header-container">
            <a href="/" class="tyc-header-logo">
                <img src="https://data.chinaecono.com/images/logo.png?t=1683356233" height="35" alt="">
                <span>经济运行平台</span>
            </a>
            <div class="tyc-header-right">
                <div class="tyc-header-nav">     
                    <div class="tyc-header-nav-item">
                        <el-link :underline="false" href="/#/login">登录</el-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div  class="user-login">
        <div class="head-bg">
        </div>
        <div class="login-area">
            <div class="app-login_wrapper app-login-new">
                <div class="right-c" style="padding-right:40px;padding-left:40px;padding-top:10px;" >
                    <div style="text-align:center;margin-bottom: 10px;">
                        <img src="https://data.chinaecono.com/images/logo.png?t=1683356233" style="margin: 20px 0;" > 
                        <!-- 淄博市项目调整 2023-04-17 -->
                        <!-- <img src="http://data.chinaecono.com/images/logo.png" style="height:90px;" >  -->
                    </div>
                    <div style="margin-bottom:20px;">
                        <span>用户注册</span>
                        <div class="fr">
                            已有账号，<el-link :underline="false" type="primary" href="/#/login" style="vertical-align: text-top;">立即登录</el-link>
                        </div>
                    </div>
                    <el-form :model="registerForm"  status-icon  :rules="registerFormRules" ref="registerFormRef" label-width="0">
                        <el-form-item label-width="0" prop="userName">
                            <el-input v-model="registerForm.userName" placeholder="用户名" autocomplete="off"  >
                            </el-input>
                        </el-form-item>
                        <el-form-item label-width="0" prop="tel">
                            <el-input v-model="registerForm.tel" placeholder="手机号" autocomplete="off"  >
                                <template #prepend>
                                    <span >中国 +86</span>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label-width="0" prop="verifyCode" style="border: 1px solid #DCDFE6;">
                            <el-input v-model="registerForm.verifyCode"  placeholder="6位数字" oninput="value=value.replace(/[^0-9.]/g,'')"  class="input-verifycode" />
                            <div class="vcode-area fr">
                                <el-link :underline="false" href="#" @click.prevent='sendVerifyCodeFun' v-if='isSend' class="vcode-btn">获取验证码</el-link>
                                <el-link :underline="false" disabled v-if='!isSend' class="vcode-btn">{{sendMsg}}</el-link>
                            </div>
                        </el-form-item>
                        <el-form-item label-width='0'  prop="password">
                            <el-input
                                type="password" placeholder="请输入新密码"
                                v-model="registerForm.password"
                                autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label-width='0'  prop="checkpass">
                        <el-input
                            type="password" placeholder="请第二次输入新密码"
                            v-model="registerForm.checkpass"
                            autocomplete="off"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label-width="0" prop="company">
                            <el-input v-model="registerForm.company" placeholder="所在单位" autocomplete="off"  >
                            </el-input>
                        </el-form-item>
                        <el-form-item label-width="0" prop="businessPerson">
                            <el-input v-model="registerForm.businessPerson" placeholder="商务联系人" autocomplete="off"  >
                            </el-input>
                        </el-form-item> -->
                        <el-form-item label-width='0' prop="isAgree">
                            <el-checkbox v-model="registerForm.isAgree" label=" "  />
                            <span>我已阅读并同意 <el-link :underline="false" href="/#/help/privacyPolicy" target="_blank">《隐私权政策》</el-link>和<el-link :underline="false" href="/#/help/userContent" target="_blank">《用户协议》</el-link></span>
                        </el-form-item>
                        <el-form-item label-width='0'>
                            <el-button  style="width: 100%;" type="primary" @click="submitForm()">立即注册</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
    
    <!-- <div class='reg_wrap'>
        <el-steps :active="stepActive" finish-status="success" process-status='success' align-center>
            <el-step title="验证手机" />
            <el-step title="设置密码" />
            <el-step title="申请成功" />
        </el-steps>
        <el-form :model="registerForm"  status-icon  :rules="registerFormRules" ref="registerFormRef" label-width="100px">
             <! -- 验证手机 -- >
            <div v-if="stepActive==0" style="width:600px;margin-left:120px;margin-top:60px;">
                <el-form-item label='姓名' prop="name">
                     <el-input v-model="registerForm.name" placeholder="姓名" autocomplete="off" style="width:60%;"  >
                    </el-input>
                </el-form-item>
                <el-form-item label='公司名称' prop="company">
                     <el-input v-model="registerForm.company" placeholder="公司名称" autocomplete="off" style="width:60%;"  >
                    </el-input>
                </el-form-item>
                <el-form-item label='公司所在省市' prop="companyCity">
                     <el-input v-model="registerForm.companyCity" placeholder="公司所在省市" autocomplete="off" style="width:60%;"  >
                    </el-input>
                </el-form-item>
                <el-form-item label='是否有商务' prop="isBusiness">
                      <el-switch v-model="registerForm.isBusiness" />
                </el-form-item>
                <el-form-item v-if='registerForm.isBusiness' label="商务联系人" prop="businessPeople">
                     <el-input v-model="registerForm.businessPeople" placeholder="商务联系人" autocomplete="off" style="width:60%;"  >
                    </el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="tel">
                    <el-input v-model="registerForm.tel" placeholder="手机号" autocomplete="off" style="width:60%;"  >
                        <template #prepend>
                            <span style="padding-right:12px;">+86</span>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label='短信验证码' prop="verifyCode">
                    <el-input v-model="registerForm.verifyCode"  placeholder="6位数字" oninput="value=value.replace(/[^0-9.]/g,'')"  style="width:60%;margin-right: 20px"/>
                    <el-button type="text" v-if='isSend' @click='sendVerifyCodeFun'>获取短信验证码</el-button>
                    <el-button type="text" v-if='!isSend' disabled>{{sendMsg}}</el-button>
                </el-form-item>
                <el-form-item label-width='100px' prop="isAgree">
                    <el-checkbox v-model="registerForm.isAgree" label=" "  />
                    <span>我已阅读并同意 <el-link :underline="false" href="/#/PrivacyPolicy" target="_blank">《隐私政策》</el-link>和<el-link :underline="false" href="/#/UserContent" target="_blank">《用户协议》</el-link></span>
                </el-form-item>
                <el-form-item label-width='100px'>
                    <el-button  class="subBtn" type="primary" @click="nextForm()">下一步，设置密码</el-button>
                </el-form-item>
            </div>
            <! -- 设置密码 --  >
            <div v-if="stepActive==1" style="width:600px;margin-left: 120px;margin-top:60px;">
                <el-form-item label="新密码" prop="password">
                     <el-input
                        type="password" placeholder="请输入新密码"
                        v-model="registerForm.password"
                        autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label='确认密码' prop="checkpass">
                 <el-input
                    type="password" placeholder="请第二次输入新密码"
                    v-model="registerForm.checkpass"
                    autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label-width='100px'>
                    <el-button  class="subBtn" type="primary" @click="submitForm()">确认</el-button>
                </el-form-item>
            </div>
        </el-form>
        <! -- 申请成功 -- >
        <div v-if="stepActive==2" style="width:600px;margin-left: 120px;margin-top:60px;">
            <div class="reg_pass" >
                <i class="el-icon-check reg_pass_icon"></i>
                <! -- <font-awesome-icon icon="check" class="" /> -- >
                <span class="reg_pass_msg">申请成功!</span>
                <br>
                <span class="reg_pass_msg">您有3天的试用期限，商务会及时联系您！</span>
                <br>
                <br>
                <el-button  class="subBtn" type="primary" @click="pushHome()">前往首页</el-button>
            </div>
        </div>
    </div> -->
     <FooterNew></FooterNew>
</div>
</template>
<script>
import { ref } from '@vue/reactivity'
import {sendVerifyCode} from "../http/loginApi";  
import {registerUser} from '../http/homeApi'
import {login} from "../http/loginApi"; 
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router';
// import {parseTime} from '@/utils'
import store from '../store'
import FooterNew from '@/components/FooterNew'
export default {
    name:'Register',
    components:{
    FooterNew
  },
    setup() {
        const router=useRouter();
        let registerForm=ref({
            userName:'',
            tel:'',
            verifyCode:'',
            isAgree:false,//是否同意《隐私政策》和《用户协议》
            password:'',
            checkpass:'',
            company:'',
            businessPerson:''
        })
        let registerFormRef = ref(null); 
        //验证两次密码是否一致
        let validatePass2 = (rule, value, callback) => { 
            if (value === '') {
                callback(new Error('请再次输入密码')) 
            } else if (value !== registerForm.value.password) {
                callback(new Error('两次输入密码不一致!'))
            } else {
                callback()
            }
        };
        let validatePass=(rule, value, callback)=>{
             //var reg = new RegExp("^(?![0-9]+$)(?![a-zA-Z]+$)(?![0-9a-zA-Z]+$)(?![0-9\\W]+$)(?![a-zA-Z\\W]+$)[0-9A-Za-z\\W]{6,18}$", "g");
             var reg =new RegExp("^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9\\W_!@#$%^&*`~()-+=]+$)(?![0-9A-Z\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{6,16}$", "g");

             if (value === '') {
                callback(new Error('请输入密码')) 
            } else if (!reg.test(value)) {
                callback(new Error('包含大小写字母、数字、特殊字符'))
            } else {
                callback()
            } 
        }
        let validateAgree=(rule, value, callback)=>{
             if (value === false) {
                callback(new Error('请先勾选“阅读并同意隐私权政策和用户协议”')) 
            } else {
                callback()
            } 
        }
        //注册提交验证
        let registerFormRules = {
            userName: [
                { required: true, message: "请输入用户名", trigger: "blur" },
            ],
            tel: [
                { required: true, message: "请输入手机号", trigger: "blur" },
                { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" },
            ],
            verifyCode: [
                { required: true, message: "请输入验证码", trigger: "blur" },
                { min: 6, max: 6, message: "长度为6字符", trigger: "blur" },
            ],
            isAgree: [
                { validator:validateAgree , trigger: "blur" } 
            ],
            password: [
                { required: true, message: "请输入密码", trigger: "blur" },
                { min: 6, max: 16, message: "长度在6-16个字符", trigger: "blur" },
                { validator:validatePass , trigger: "blur" } 
            ],
            checkpass: [
                { required: true, message: "请第二次输入密码", trigger: "blur" },
                { min: 6, max: 16, message: "长度在6-16个字符", trigger: "blur" },
                { validator:validatePass2 , trigger: "blur" } 
            ],
            // company: [
            //     { required: true, message: "请输入所在单位", trigger: "blur" },
            // ],
            
        };
        
        let sendMsg=ref('发送验证码')
        let isSend=ref(true)
        //获取验证码
        const sendVerifyCodeFun=()=>{
            let obj = registerFormRef.value;  
            console.log(obj);
            obj.validateField(['tel'],(isValid) => {
                console.log(isValid);
                if(!isValid){
                    console.log(isValid);
                    sendVerifyCode(registerForm.value.tel,2)
                    .then((res) => {
                        console.log(res)
                        if ( res.code == 20000) {
                            //Message.success(res.data.msg)
                            isSend.value = false;
                            let timer = 60;
                            sendMsg.value = timer + "s后可重发";
                            const timeFun = setInterval(() => {
                                --timer;
                                sendMsg.value = timer + "s后可重发";
                                if (timer == 0) {
                                    isSend.value = true;
                                    sendMsg.value = "获取短信验证码";
                                    clearInterval(timeFun);
                                }
                            }, 1000);
                        }
                    });
                }
            });
        }
        //验证手机是否已注册
        // const nextForm=()=>{
        //     let obj = registerFormRef.value;  
        //     let noPassNum=0
        //     obj.validateField(['password','checkpass','tel','verifyCode','isAgree'],(isValid) => {
        //         console.log(isValid);
        //         if(isValid){
        //             noPassNum++;
        //         }
        //     });
        //     if(noPassNum<1){
        //         isUserExist(registerForm.value.tel,registerForm.value.verifyCode).then(res=>{
        //             console.log(res);
        //             if(res.code==20000 && res.data==true){
        //                  ElMessage.error({
        //                     message: '此手机号已注册，请直接登录', 
        //                 });  
        //             }
        //             else if(res.code==20000 && res.data==false){
        //                 stepActive.value=1;
        //             }
        //         })
        //     }
        // }
        //注册用户
        const submitForm= ()=>{
            let obj = registerFormRef.value;  
            obj.validate((isValid) => {
                console.log(isValid);
                if(isValid){
                    let params={
                        userName:registerForm.value.userName,
                        tel:registerForm.value.tel,
                        verifyCode:registerForm.value.verifyCode,
                        userPassword:registerForm.value.password,
                        type:0,
                        company:registerForm.value.company,
                        businessPerson:registerForm.value.businessPerson
                    }
                    registerUser(params).then((res)=>{
                        console.log(res);
                        if(res.code==20000){
                            //注册成功后登录
                            login({tel:registerForm.value.tel,password:registerForm.value.password,loginType:0,appId:20}).then(res=>{
                                console.log(res);
                                if(res.code==20000){
                                    localStorage.setItem("accesstoken",res.data.accessToken); 
                                    localStorage.setItem("refreshToken",res.data.refreshToken);
                                    localStorage.removeItem('navMenuData');//移除菜单列表
                                    localStorage.setItem("currentUser",JSON.stringify(res.data.currentUser)); 
                                    store.commit("setIsLoginVisable", false);
                                    
                                    pushHome();
                                }
                                
                            });
                        }
                        else{
                            ElMessage.error({
                                message: res.message, 
                            });  
                        }
                    });
                }
            });
           
        }
        //前往首页
        const pushHome=()=>{
            router.push('/');
        }
        return{
            registerForm,
            registerFormRef,
            registerFormRules,
            sendMsg,
            isSend,
            sendVerifyCodeFun,
            submitForm,
            pushHome
        }
    },
}
</script>
<style  lang="scss" scoped>
@import '@/styles/variables.scss';
.t1_top_layer {
    background: rgba(14, 35, 60, 0.5);
    border-bottom: 1px solid rgba(63, 148, 253, 0.3);
}
.tl_header_T0v0W{
    min-width: 1080px;
    max-width: 1180px;
    margin: auto;
    height: 80px;
    padding:0 20px;
}
.tl_link_wrap_EyH-F{
    float: right;
    margin-top: 30px;
    font-size: 14px;
}
.reg_wrap {
    margin: 40px auto;
    width: 1000px;
    padding: 0 10px;
    min-height: 500px;
}
.reg_pass{
    padding-left: 60px;
    position: relative;
    margin-left: 120px;
}
.reg_pass_msg{
    font-size: 18px;
    color: rgba(255,216,148);
}
.reg_pass_icon{
    font-size: 32px;
    color: #67C23A;
    position: absolute;
    left: 0;
}
.vcode-area {
    height: 40px;
    width: 100px;
    box-sizing: border-box;
    position: absolute;
    right: 0px;
    top: 0px;
}
.input-verifycode{
    width: 70%;
    ::v-deep .el-input__inner{
        border: none;
    }
}
.vcode-btn {
    top: 50%;
    transform: translateY(-50%);
    width: 86px;
    text-align: center;
    font-size: 12px;
    position: absolute;
    right: 4px;
    cursor: pointer;
    min-width: 88px;
    display: inline-block;
}
.vcode-btn:after {
    content: " ";
    position: absolute;
    width: 1px;
    height: 28px;
    background: #d8d8d8;
    right: 90px;
    top: 6px;
    height: 25px;
}
// ::v-deep .el-link.el-link--default {
//     color: $specialtext-color;
// }
// ::v-deep .el-link.el-link--default:hover {
//     color:darken($specialtext-color,10%);
// }
// ::v-deep .el-form-item__label{
//     color:#c0c4cc;

// }
</style>
